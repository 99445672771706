@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding-bottom: 0;
}

body:has(main[data-color-scheme="home"]) {
  color: var(--grey-900);
  --background-page: var(--hero-video-background);
  background: var(--background-page);
  --text-base: 0 0% 0%;
}

body:has(main[data-color-scheme="oval"]) {
  --background-base: 0 0% 13.8%; /* default background is correct for mac users */
  --background-page: hsl(var(--background-base));
  --text-base: var(--color-white);
  --border-base: 0 0% 20%;
  --foreground-base: 0 0% 18%;
  background: var(--background-page);
  color: hsl(var(--text-base));
}

body:has(main[data-color-scheme="osnap"]) {
  color: var(--grey-900);
  --background-base: var(--color-white);
  --background-page: hsl(var(--background-base));
  background: var(--background-page);
  --text-base: 0 0% 0%;
}

body:has(main[data-color-scheme="oval"][data-platform="mac"]) {
  --background-base: 0 0% 13.8%;
}

body:has(main[data-color-scheme="oval"][data-platform="windows"]) {
  --background-base: 0 0% 12%;
  --text-base: 0 0% 100%;
}

html {
  /* Making sure text size is only controlled by font-size */
  -webkit-text-size-adjust: none;
  /* Improve text rendering */
  -webkit-font-smoothing: antialiased;
  /* https://marco.org/2012/11/15/text-rendering-optimize-legibility */
  text-rendering: optimizeSpeed;
  font-family: "Halyard Display", sans-serif;
}

:root {
  /* colors */
  --color-black: 0 0% 0%;
  --color-white: 0 0% 100%;
  --color-grey-25: 0 0% 99%;
  --color-grey-50: 0 0% 98%;
  --color-grey-100: 0 0% 96%;
  --color-grey-200: 0 0% 93%;
  --color-grey-300: 0 0% 88%;
  --color-grey-400: 255 3% 69%;
  --color-grey-500: 285 1% 51%;
  --color-grey-600: 288 2% 35%;
  --color-grey-700: 288 4% 25%;
  --color-grey-800: 285 4% 19%;
  --color-grey-900: 280 4% 15%;
  --color-grey-950: 0 0% 8%;
  --color-primary-25: 0 100% 99%;
  --color-primary-50: 0 100% 98%;
  --color-primary-100: 0 100% 96%;
  --color-primary-200: 0 95% 92%;
  --color-primary-300: 0 89% 86%;
  --color-primary-400: 0 85% 77%;
  --color-primary-500: 0 100% 65%;
  --color-primary-600: 0 85% 57%;
  --color-primary-700: 0 54% 52%;
  --color-primary-800: 0 48% 42%;
  --color-primary-900: 0 45% 34%;
  --color-primary-950: 0 55% 24%;
  --color-error-25: 4 88% 99%;
  --color-error-50: 4 88% 97%;
  --color-error-100: 4 95% 94%;
  --color-error-200: 4 98% 89%;
  --color-error-300: 4 96% 80%;
  --color-error-400: 4 93% 69%;
  --color-error-500: 4 86% 58%;
  --color-error-600: 4 74% 49%;
  --color-error-700: 4 76% 40%;
  --color-error-800: 4 72% 33%;
  --color-error-900: 8 65% 29%;
  --color-error-950: 8 75% 19%;
  --color-warning-25: 44 100% 98%;
  --color-warning-50: 44 100% 96%;
  --color-warning-100: 44 98% 89%;
  --color-warning-200: 44 99% 77%;
  --color-warning-300: 42 99% 65%;
  --color-warning-400: 39 98% 56%;
  --color-warning-500: 34 94% 50%;
  --color-warning-600: 28 97% 44%;
  --color-warning-700: 22 92% 37%;
  --color-warning-800: 19 84% 31%;
  --color-warning-900: 18 80% 26%;
  --color-success-25: 144 83% 98%;
  --color-success-50: 144 83% 96%;
  --color-success-100: 140 75% 92%;
  --color-success-200: 144 68% 80%;
  --color-success-300: 148 63% 67%;
  --color-success-400: 150 57% 54%;
  --color-success-500: 152 77% 39%;
  --color-success-600: 153 91% 30%;
  --color-success-700: 155 90% 24%;
  --color-success-800: 155 84% 20%;
  --color-success-900: 156 83% 16%;
  --black: hsl(var(--color-black));
  --white: hsl(var(--color-white));
  --grey-25: hsl(var(--color-grey-25));
  --grey-50: hsl(var(--color-grey-50));
  --grey-100: hsl(var(--color-grey-100));
  --grey-200: hsl(var(--color-grey-200));
  --grey-300: hsl(var(--color-grey-300));
  --grey-400: hsl(var(--color-grey-400));
  --grey-500: hsl(var(--color-grey-500));
  --grey-600: hsl(var(--color-grey-600));
  --grey-700: hsl(var(--color-grey-700));
  --grey-800: hsl(var(--color-grey-800));
  --grey-900: hsl(var(--color-grey-900));
  --grey-950: hsl(var(--color-grey-950));
  --primary-25: hsl(var(--color-primary-25));
  --primary-50: hsl(var(--color-primary-50));
  --primary-100: hsl(var(--color-primary-100));
  --primary-200: hsl(var(--color-primary-200));
  --primary-300: hsl(var(--color-primary-300));
  --primary-400: hsl(var(--color-primary-400));
  --primary-500: hsl(var(--color-primary-500));
  --primary-600: hsl(var(--color-primary-600));
  --primary-700: hsl(var(--color-primary-700));
  --primary-800: hsl(var(--color-primary-800));
  --primary-900: hsl(var(--color-primary-900));
  --primary-950: hsl(var(--color-primary-950));
  --error-25: hsl(var(--color-error-25));
  --error-50: hsl(var(--color-error-50));
  --error-100: hsl(var(--color-error-100));
  --error-200: hsl(var(--color-error-200));
  --error-300: hsl(var(--color-error-300));
  --error-400: hsl(var(--color-error-400));
  --error-500: hsl(var(--color-error-500));
  --error-600: hsl(var(--color-error-600));
  --error-700: hsl(var(--color-error-700));
  --error-800: hsl(var(--color-error-800));
  --error-900: hsl(var(--color-error-900));
  --error-950: hsl(var(--color-error-950));
  --warning-25: hsl(var(--color-warning-25));
  --warning-50: hsl(var(--color-warning-50));
  --warning-100: hsl(var(--color-warning-100));
  --warning-200: hsl(var(--color-warning-200));
  --warning-300: hsl(var(--color-warning-300));
  --warning-400: hsl(var(--color-warning-400));
  --warning-500: hsl(var(--color-warning-500));
  --warning-600: hsl(var(--color-warning-600));
  --warning-700: hsl(var(--color-warning-700));
  --warning-800: hsl(var(--color-warning-800));
  --warning-900: hsl(var(--color-warning-900));
  --success-25: hsl(var(--color-success-25));
  --success-50: hsl(var(--color-success-50));
  --success-100: hsl(var(--color-success-100));
  --success-200: hsl(var(--color-success-200));
  --success-300: hsl(var(--color-success-300));
  --success-400: hsl(var(--color-success-400));
  --success-500: hsl(var(--color-success-500));
  --success-600: hsl(var(--color-success-600));
  --success-700: hsl(var(--color-success-700));
  --success-800: hsl(var(--color-success-800));
  --success-900: hsl(var(--color-success-900));
  /* oval colors */
  --oval-black: 0 0% 12%;

  /* legacy 'red' color -- renamed to 'primary' */
  --red: hsl(var(--color-primary-500));
  /* special hero video background var */
  --hero-video-background: #272528;
  /* fonts */
  --header-lg: 400 6rem/112px "Halyard Display", sans-serif;
  --header-md: 400 4rem/72px "Halyard Display", sans-serif;
  --header-sm: 400 2.5rem/55px "Halyard Display", sans-serif;
  --header-xs: 400 2rem/45px "Halyard Display", sans-serif;
  --sub-header: 400 1rem/19px "Halyard Display", sans-serif;
  --sub-header-sm: 400 0.75rem/16px "Halyard Display", sans-serif;
  --body-xl: 400 1.625rem/32px "Halyard Display", sans-serif;
  --body-lg: 400 1.25rem/28px "Halyard Display", sans-serif;
  --body-md: 400 1.125rem/24px "Halyard Display", sans-serif;
  --body-sm: 400 1rem/22px "Halyard Display", sans-serif;
  --body-xs: 400 0.75rem/17px "Halyard Display", sans-serif;
  --lg-fluid-font-size: clamp(4rem, 6vw + 2rem, 6rem);
  --md-fluid-font-size: clamp(2.5rem, 6vw + 2rem, 4rem);
  --sm-fluid-font-size: clamp(2rem, 7vw + 1.18rem, 2.5rem);
  /* animation */
  --animation-duration: 0.3s;
  /* containers */
  --desktop-page-padding: 32px;
  --tablet-page-padding: 24px;
  --mobile-page-padding: 16px;
  --page-padding: var(--desktop-page-padding);
  @media (max-width: 1024px) {
    --page-padding: var(--tablet-page-padding);
  }
  @media (max-width: 768px) {
    --page-padding: var(--mobile-page-padding);
  }
  --desktop-page-width: 1144px;
  --tablet-page-width: 896px;
  --mobile-page-width: 608px;
  --page-width: var(--desktop-page-width);
  @media (max-width: 1024px) {
    --page-width: var(--tablet-page-width);
  }
  @media (max-width: 768px) {
    --page-width: var(--mobile-page-width);
  }
  --desktop-header-height: 48px;
  --mobile-header-height: 32px;
  --header-height: var(--desktop-header-height);
  @media (max-width: 768px) {
    --header-height: var(--mobile-header-height);
  }
  --header-blur-height: 92px;
  --vote-ticker-height: 68px;

  --sp-layout-height: 347px !important;
}

dialog::backdrop {
  background-color: hsl(0 0% 0% / 0.8);
  backdrop-filter: blur(4px);
  transition:
    background-color 0.3s,
    backdrop-filter 0.3s;
}

.text-gradient-oval {
  background: linear-gradient(to right, white, hsla(0, 0%, 72%, 1));
  color: transparent;
  background-clip: text;
}
